var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-1",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('v-data-table',{staticClass:"rounded-xl elevation- pa-1 px-5",attrs:{"headers":_vm.headerBibits,"items":_vm.data.result.monitoring_tree_detail,"search":'',"footer-props":{
            showCurrentPage: false,
            showFirstLastPage: false,
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Detail Pohon ")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])])]},proxy:true},{key:"item.index",fn:function({ index }){return [_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(index + 1)+" ")])]}}])})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5 px-4",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Titik Koordinat Monitoring")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),_c('div',[_c('div',{ref:"mapContainer",staticClass:"map-container",staticStyle:{"width":"100%","height":"400px","border-radius":"10px"},attrs:{"id":"mapContainer"}})])])],1),_c('v-col',[_c('v-card',{staticClass:"geko-base-detail-card mb-5 px-4",attrs:{"data-aos":"fade-up","data-aos-delay":"100","data-aos-duration":"800"}},[_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Foto Dokumentasi Monitoring")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                  backgroundImage:
                      'url(' +
                      _vm.$_config.baseUrlUpload +
                      '/' +
                      _vm.data.result.photo1 ?? '' +
                      ')',
                  }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.photo1 ?? '')}}},[_c('h6',[_vm._v("Foto Dokumentasi 1")])]),_c('div',{staticClass:"absent-photo-item",style:({
                  backgroundImage:
                      'url(' +
                      _vm.$_config.baseUrlUpload +
                      '/' +
                      _vm.data.result.photo2 ?? '' +
                      ')',
                  }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.photo2 ?? '')}}},[_c('h6',[_vm._v("Foto Dokumentasi 2")])])]):_vm._e(),_c('div',{staticClass:"list-header py-3 mt-1"},[_c('div',{staticClass:"pr-5 mr-5 d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('h4',[_vm._v("Foto Akumulasi Pohon Monitoring")])]),_c('div',{staticClass:"d-flex flex-row geko-list-header-action"},[_c('div',{staticClass:"geko-list-header-toolbar"})])]),(true)?_c('div',{staticClass:"absent-photo-list d-flex flex-row"},[_c('div',{staticClass:"absent-photo-item",style:({
                  backgroundImage:
                      'url(' +
                      _vm.$_config.baseUrlUpload +
                      '/' +
                      _vm.data.result.tree_detail_acumulation[0].photo_dead ?? '' +
                      ')',
                  }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.tree_detail_acumulation[0].photo_dead ?? '')}}},[_c('h6',[_vm._v("Foto Pohon Mati")])]),_c('div',{staticClass:"absent-photo-item",style:({
                  backgroundImage:
                      'url(' +
                      _vm.$_config.baseUrlUpload +
                      '/' +
                      _vm.data.result.tree_detail_acumulation[0].photo_life ?? '' +
                      ')',
                  }),on:{"click":function($event){return _vm.showLightbox(_vm.$_config.baseUrlUpload + '/' + _vm.data.result.tree_detail_acumulation[0].photo_life ?? '')}}},[_c('h6',[_vm._v("Foto Pohon Hidup")])])]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }